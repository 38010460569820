import React, { useState, useEffect } from "react";
import { Avatar, Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  Dashboard as DashboardIcon,
  Receipt as ReceiptIcon,
  AccountBalance as AccountBalanceIcon ,
  LockOpen as LockOpenIcon ,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Security as SecurityIcon,
  OfflineBolt as OfflineBolt,  
  Store as Storeicon,

} from "@material-ui/icons";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AssessmentIcon from '@material-ui/icons/Assessment';
import RuleIcon from '@mui/icons-material/Rule';
import PaymentIcon from '@mui/icons-material/Payment';

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";
import papel from '../papel.png';
import logo from '../../logo/logo.png';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [  
  { id: 0, label: "Home", link: "/app/home", icon: <HomeIcon /> },
  { id: 1, label: "Dashboard", link: "/app/dashboard", icon: <DashboardIcon /> },
  {
    id: 2,
    label: "Wire",
    link: "/app/wire",
    icon: <ReceiptIcon />,
  },  
  {
    id: 4,
    label: "Quick Pay",
    link: "/app/quickpay",
    icon: <OfflineBolt />,
  },
  {
    id: 5,
    label: "Password",
    link: "/app/password",
    icon: <LockOpenIcon />,
  },
  
  {
    id: 7,
    label: "Security Settings",
    link: "/app/security",
    icon: <SecurityIcon />,
  },
  {
    id: 8,
    label: "Report",
    link: "/app/report",
    icon: <AssessmentIcon />,    
  },
  {
    id: 12,
    label: "Terminal",
    link: "/app/terminal",
    icon: <PointOfSaleIcon />,    
  },
  {
    id: 12,
    label: "Transactions",
    link: "/app/txn",
    icon: <PaymentIcon />,    
  },
  {
    id: 13,
    label: "Store management",
    link: "/app/store",
    icon: <Storeicon />,    
  },  
  /*{
    id: 10,
    label: "Fraud Rules",
    link: "/app/fraudmanagement",
    icon: <RuleIcon />,
  },*/
  
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer 
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>                   
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>      
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
