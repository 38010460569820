import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

//components
import { ThemeProvider, createTheme } from '@mui/material';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import { InputLabel, 
	Select,
	MenuItem, 
	Button, 
	Box,  
	FormControl, 
	Input, 
	FormControlLabel, 
	Checkbox,
	TextField, 
	FormHelperText,
	Grid 
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { forwardRef } from 'react';
import Refresh from '@material-ui/icons/Refresh';
import QRCode from 'react-qr-code';
import MUIDataTable from "mui-datatables";
import PageTitle from "../../components/PageTitle/PageTitle";
import MaterialTable, { MTableToolbar, MTablePagination } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

//pages
import ApiService from '../ApiService/ApiService';

//style
//import useStyles from "./styles";

const useStyles = theme => ({
	root: {
		display: 'flex',
	},
});
const defaultMaterialTheme = createTheme();
class QuickPaymentComponent extends Component {
	constructor(props) {
	  super();
	  let params = window.location.origin;
	  this.state = {
		payments: [],		
		failed: false,
		success: false,
		responsemessage: null,
		amount: null,
		terminalId: null,
		terminals: [],	
		showCreate: false,	
		currency: null,
		reamarks: null,
		location: params,
		qrvalue : null,
		showQRCodePanelVisible : false,
	  }
		this.createPayment = this.createPayment.bind(this);
		this.fetchPayments = this.fetchPayments.bind(this);
		this.cancelPayment = this.cancelPayment.bind(this);
		this.showCreateDialogue = this.showCreateDialogue.bind(this);
		this.hideCreateDialogue = this.hideCreateDialogue.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleTerminalChange = this.handleTerminalChange.bind(this);

		this.showQRCodePanel = this.showQRCodePanel.bind(this);
		this.hideQRCodePanel = this.hideQRCodePanel.bind(this);
	}

	showQRCodePanel(value) {
		this.setState({qrvalue: value ,showQRCodePanelVisible: true});
	}
	hideQRCodePanel() {
		this.setState({showQRCodePanelVisible: false})
	}

	componentDidMount(){
   		this.fetchPayments();
		this.fetchTerminals();
		
  	} 
	fetchPayments() {	
		ApiService.fetchQuickPayments().then((res) => {
			this.setState({payments: res.data!=null?res.data:[] })
		}).catch(error => {
			console.log(error);
		  });
		console.log(this.state.payments);
	}
	fetchTerminals() {
		ApiService.fetchMerchantTerminals().then((res) => {
			this.setState({terminals: res.data})
		});
	}
	cancelPayment(data) {
		ApiService.cancelQuickPayment(data.qpId).then((res) => {
			if(res.data.status=='OK') {				
				this.setState({success: true, failed: false, responsemessage: res.data.responseMessage})
				this.fetchPayments();
			} else {
				this.setState({success: false, failed: true, responsemessage: res.data.responseMessage})
			}
		})
		
	}
	showCreateDialogue(){
		this.setState({showCreate: true});
	}
	hideCreateDialogue(){
		this.setState({showCreate: false});
	}
	createPayment() {
		ApiService.createQuickPayment(this.state.terminalId.terminalId, this.state.amount, this.state.remarks).then((res) => {
			if(res.data.status=='OK') {
				this.setState({success: true, failed: false, responsemessage: res.data.responseMessage, showCreate: false})
				this.fetchPayments();
			} else {
				this.setState({success: false, failed: true, responsemessage: res.data.responseMessage, showCreate: false})
			}
		})
		
	}
	handleInputChange(event) {
		const target = event.target;
		const inputName = target.name;
		const inputValue = target.value;
		this.setState({
			[inputName]: inputValue
		});
	}
	handleTerminalChange(event) {
		this.setState({ terminalId: event.target.value, currency: event.target.value.terminalCurrency });
		 
	}
	
	tableIcons = {
	    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
	  };
	render() {
	const { classes } = this.props;	
	
    return (
					
					<Paper style={{margin: '1em', padding: '1em'}}>
						<PageTitle title="Payment links" />
					   
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
					
					 <ThemeProvider theme={defaultMaterialTheme}>
					<MaterialTable
					  icons={this.tableIcons}
				      title="Quick Pay" 					 					  
					  
				      //columns={[ 
						
						/*{name:'qpId', label:'Qpid',
						options:{							
							customBodyRender  : (rowData) => {
								return(
									<Tooltip title={rowData} interactive>
										<InfoOutlinedIcon/>
									</Tooltip>
								);								
							}
						}
						},

						{ name: 'terminalId', label: 'Terminal', 
						options:{
							customBodyRender  : (rowData) => {								
								return(
									<Tooltip title={rowData} interactive>
										<InfoOutlinedIcon/>
									</Tooltip>
								);
																																														
							}
						}																								
						}, 						
						{name: 'amount', label: 'Amount', type: 'numeric',
						options:{
							customBodyRender:(rowData) => {
								return(
									<Typography variant="body2">{rowData && rowData.toFixed(2)}</Typography>
								);
								
							}
						}						 
						},			
						"currency","remarks",
						{name:'Id', label:'Payment Link',
						options:{
							customBodyRender  : (rowData) => {
								return(
									<a target="_blank" href={"https://checkout.monitox.co.uk/quickCheckout?id=" + rowData}>https://checkout.monitox.co.uk/quickCheckout?id={rowData}</a>
								);
								
							}
						}

						},
						{name:'Id', label:'Qr Code',
						options:{
							customBodyRender  : (rowData) => {
								return(
									<Button variant="contained" color="primary" onClick={() => this.showQRCodePanel(rowData)}>
										QRCode
									</Button>		
								);
								
							}
						}

						},
						"status",
				      ]}*/
					columns={[ 
						    { field: 'qpId', title: 'Id',render: rowData => <>
								<Tooltip title={rowData.qpId} interactive>
									 <InfoOutlinedIcon/>
								</Tooltip>
							</>
						},
						 { field: 'terminalId', title: 'Terminal',render: rowData => <>
							<Tooltip title={rowData.terminalId} interactive>
								 <InfoOutlinedIcon/>
							</Tooltip>
						</>
						}, 
						{field: 'amount', title: 'Amount', type: 'numeric', render: rowData=><Typography variant="subtitle2">{rowData.amount && rowData.amount.toFixed(2)}</Typography> },
						{ field: 'currency' , title: 'Currency',cellStyle: {fontSize: 12,fontFamily: "sans-serif",}},	 
						{ field: 'remarks' , title: 'Remark',cellStyle: {fontSize: 12,fontFamily: "sans-serif",}},	 
				        { field: 'link' , title: 'Payment Link', render: rowData => <a target="_blank" href={"https://checkout.monitox.co.uk/quickCheckout?id=" + rowData.qpId}><Typography variant="subtitle2">https://checkout.monitox.co.uk/quickCheckout?id={rowData.qpId}</Typography></a>},
						{ field: 'status' , title: 'Status',cellStyle: {fontSize: 12,fontFamily: "sans-serif",}},
						 { field: 'id', title: 'Qr code',render: rowData => <>
							<Button variant="contained" color="primary" onClick={() => this.showQRCodePanel(rowData)}>
								QRCode
							</Button>
						</>
						}, 
				      ]}
				       
				      data={this.state.payments}
					  actions={[
						{
							icon: (props)=> <Button variant="contained" color="primary" size="small" className={classes.button} startIcon={<AddBox />}>Create payment</Button>,
							tooltip: 'Create payment link', 
							isFreeAction: true,
							onClick: (event) => this.showCreateDialogue(),
						},
						{
							icon: (props)=> <Button variant="contained" color="primary" size="small" className={classes.button} startIcon={<Refresh />}>Refresh</Button>,
							tooltip: 'Refresh table', 
							isFreeAction: true,
							onClick: (event) => this.fetchPayments(),
						},
						{
							tooltip: 'Cancel payment',
							icon: (props)=> <DeleteIcon {...props} />,
							onClick: (evt, data) => this.cancelPayment(data),
						}, 
					  ]}
					  options={{		
						pageSize: 30,
						pageSizeOptions: [30,45,60],
						padding: 'dense',
						showEmptyDataSourceMessage: true,
						selection: false,
						emptyRowsWhenPaging: false,
						actionsColumnIndex: 8,
						search: false,
				      }}				     
				    />
					</ThemeProvider>
					<Dialog open={this.state.showQRCodePanelVisible} onClose={this.hideQRCodePanel}>
						<DialogTitle id="customized-dialog-title" onClose={this.hideQRCodePanel}>
			          		QR Code
			        	</DialogTitle>
						<DialogContent>
						<QRCode size={256}
                			style={{ height: "auto", maxWidth: "100%", width: "100%" }} viewBox={`0 0 256 256`}
							value={"https://checkout.papelpayments.com/quickCheckout?id=" + this.state.qrvalue} />
						</DialogContent>															
					</Dialog>

				  <Dialog open={this.state.showCreate} onClose={this.hideCreateDialogue}>
					<DialogTitle id="customized-dialog-title" onClose={this.hideCreateDialogue}>
						Create Quick payment link
			        </DialogTitle>
					<DialogContent>
						 <form>
								<FormControl fullWidth margin="dense">
									<InputLabel id="terminalIdLable">Terminal</InputLabel>
									<Select value={this.state.terminalId} error={this.state.terminalId==null}
										onChange={this.handleTerminalChange} name="terminalId" 
										labelId="terminalIdLable" id="terminalId" aria-describedby="terminalId-helper-text" >
										<MenuItem key="" value="">&nbsp; </MenuItem>
										{this.state.terminals && this.state.terminals.map((mt) =>
											<MenuItem key={mt.terminalId} value={mt} dense>{mt.label + "-" + mt.terminalCardType}</MenuItem>
										)}
									</Select>
									{this.state.terminalId==null &&	<FormHelperText id="terminalId-helper-text" error>Value is required</FormHelperText>}
								</FormControl>
								  <Grid container>
						           {this.state.currency && 
									<Grid item xs={2}>
										<TextField readonly value={this.state.currency} placeholder="Currency"  margin="dense"/>
								  		 
									</Grid>}
									<Grid item xs={this.state.currency?10:12 } >
									  <TextField name="amount" placeholder="Amount" fullWidth  margin="dense" error={this.state.amount==null}
										value={this.state.amount} onChange={this.handleInputChange} required helperText={this.state.amount==null 
										&& "Value is required" }/>  
									</Grid>
									 </Grid>
								 <TextField name="remarks" placeholder="Remarks" fullWidth  margin="dense"  
									value={this.state.remarks} onChange={this.handleInputChange}  /> 
						  </form>	
						<Box align="center">
							<Button onClick={this.createPayment} variant="contained" color="primary" >Create</Button>
						</Box>
					</DialogContent>
				</Dialog> 
				 
				</Paper>
    );
  }
}

export default withStyles(useStyles)(QuickPaymentComponent);