import React, { Component } from 'react';

import ApiService from '../ApiService/ApiService';
import Documents from './merchant/onboarding/Documents';
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AmendUserForm from './AmendUserForm';
import BusinessUserForm from './BusinessUserForm';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { withStyles, styled, tableCellClasses  } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'; 
import Grid from '@material-ui/core/Grid'; 
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow  from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import apipdf from './api.pdf';
import PageTitle from "../../components/PageTitle/PageTitle";

const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
 searchbar: {
	margin: theme.spacing(2),
  },
 tableHeader: {
	 backgroundColor: theme.palette.common.black,
	 color: theme.palette.common.white,
 },
 testMode: {
	backgroundColor: '#ffd67d',
},	
 liveMode: {
	backgroundColor: '#a4cc89',
}	
});


class Home extends Component {

	constructor(props) {
        super()
		 this.state = {
		    merchant: [],
			tabValue: 0,
			signKey: null,
			newSecret: null,
			failed: false,
			success: false,
			responsemessage: null,
			terminals: [],
			canModify: false,
			merchOnboarded: false,
			editingMerchant: false,
			editingUser: false,
			currUser: null,
			usrFirstName: '',
			numPages:null,
			pageNumber:1,
		}
		
		this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);	
		this.generateSecret = this.generateSecret.bind(this);
		this.updateSignkey = this.updateSignkey.bind(this);
		this.updateSecretOnServer = this.updateSecretOnServer.bind(this);
		this.generateNewSignkey = this.generateNewSignkey.bind(this); 
		this.fetchTerminals = this.fetchTerminals.bind(this); 
		this.fetchUserAccess = this.fetchUserAccess.bind(this); 
		this.fetchOnboarded = this.fetchOnboarded.bind(this);
		this.fetchBusinessDetails = this.fetchBusinessDetails.bind(this);
		this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
		
  	}

	componentDidMount(){		
		this.fetchBusinessDetails();
		this.fetchOnboarded();
		this.fetchTerminals();
		this.fetchUserAccess();
	}

	fetchBusinessDetails(){

		ApiService.getUserMerchant().then((res) => {
			this.setState({merchant: res.data, signkey: res.data.signkey});
			console.log(res.data);			
		});
		
	}

	fetchOnboarded() {		
		ApiService.getCurrentUser().then((res) => {
			this.setState({currUser: res.data, merchOnboarded: res.data.onboarded});
		});
	}	

	onDocumentLoadSuccess({ numPages }){
		this.setState({numPages});
	}
	
 	reloadData(){
	
	}	
		
  	fetchTerminals() {
		ApiService.fetchMerchantTerminals().then((res) => {
			this.setState({terminals: res.data})
		});
	}

	fetchUserAccess() {
		ApiService.checkAccessByMenuAndAction('Home', 'AMEND').then((res) => {
			if(res && res.data != null && res.data.id != null) {
				this.setState({canModify: true});
			}
		});
		
	}

  	generateNewSignkey() {
		const skey = this.generateSignkey();
		this.setState({signKey: skey});
  	}

  	updateSignkey(){
	if(this.state.signKey && this.state.signKey != '') {
		ApiService.updateMerchantSignkey(this.state.merchant.id, this.state.signKey).then((res) => {
			if(res.data.status === 'OK') {
				this.setState({success: true, failed: false, responsemessage: res.data.responseMessage});
			} else {
				this.setState({success: false, failed: true, responsemessage: res.data.responseMessage});
			}
		});
	}
  }
	generateSecret() {
		const pass = this.generatePassword();
		this.setState({newSecret: pass});
	}
	updateSecretOnServer() {
		if(this.state.newSecret && this.state.newSecret != '') {
			ApiService.updateMerchantSecret(this.state.merchant.id, this.state.newSecret).then((res) => {
				if(res.data.status === 'OK') {
					this.setState({success: true, failed: false, responsemessage: res.data.responseMessage});
				} else {
					this.setState({success: false, failed: true, responsemessage: res.data.responseMessage});
				}
			});
		}
			
	}

	generatePassword = (
			length = 20,
			wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$"
		) => Array(length)
			.fill('')
			.map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
			.join('');
	
	generateSignkey = (
			length = 12, 
			wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
			) => Array (length)
				.fill('')
				.map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
				.join('');
			
	handleChange(event) {
			this.setState(
				{
					[event.target.name]
						: event.target.value
				}
			)
	}

  	render() {
		const { classes } = this.props;
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		
		[`&`]: {
			fontSize: 14,
		},
		}));
		
		const StyledTableRow  = styled(TableRow )(({ theme }) => ({
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
		}));

    	return ( 
		<Paper style={{margin: '1em', padding: '1em'}}>
		<Grid container direction="row" justifyContent="space-between">
				<Grid item xs={6}>
				<PageTitle title="Welcome" />
				</Grid>
				<Grid item xs={4}>
					
					{this.state.merchOnboarded? ( 				 				
					 <Chip label="Onboarding status : Live" className={classes.liveMode} />
					):(				<>	 				 				
					 <Chip label="Onboarding status : Pending" className={classes.testMode} /> 
					 <Tooltip title="Please complete merchant profile and upload documents to get approval, once you are approved and Live you can start processing payments">
					  <IconButton>
					    <InfoIcon />
					  </IconButton>
					</Tooltip></>
					)}
					
				</Grid>
			</Grid>
			<Divider light className={classes.searchbar}/>
			{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
			{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
		    {this.state.merchant &&
			<form>
			 
			<Tabs>
			    <TabList>
				  <Tab><Typography variant="subtitle2">User Details</Typography></Tab>
			      <Tab><Typography variant="subtitle2">Business Details</Typography></Tab>
				  <Tab><Typography variant="subtitle2">Merchant Documents</Typography></Tab>
			      <Tab><Typography variant="subtitle2">Terminal Details</Typography></Tab>
				  <Tab><Typography variant="subtitle2">API Access Details</Typography></Tab>
			    </TabList>
				<TabPanel>
					{this.state.currUser && 
						<AmendUserForm user={this.state.currUser} handler={this.fetchOnboarded}/>
					}
				</TabPanel>
			    <TabPanel>
					{this.state.merchant && 
						<BusinessUserForm user={this.state.merchant} handler={this.fetchBusinessDetails}/>
					}
			    </TabPanel>
				 <TabPanel>
					<Documents />
				</TabPanel>
			    <TabPanel>
			      <Table>
									<TableHead>
									 	<StyledTableRow >
											<StyledTableCell align="left" colspan="4"  className={classes.tableHeader}>  Terminal Details </StyledTableCell>
										</StyledTableRow >
									</TableHead>
									<TableBody>
										<StyledTableRow >
											<StyledTableCell align="left">Terminal ID</StyledTableCell>
             								<StyledTableCell align="left">Currency </StyledTableCell>
											<StyledTableCell align="left">Card type </StyledTableCell> 
											<StyledTableCell align="left">Live or Test</StyledTableCell> 
										</StyledTableRow >
										{this.state.terminals && this.state.terminals.map((terminal) => (
											<StyledTableRow >
												<StyledTableCell align="left">{terminal.terminalId}</StyledTableCell>
	             								<StyledTableCell align="left">{terminal.terminalCurrency} </StyledTableCell>
												<StyledTableCell align="left">{terminal.terminalCardType} </StyledTableCell> 
												<StyledTableCell align="left">
														{terminal.testTerminal? (<>Test</>):(<>Live</>)}
												
												</StyledTableCell> 
											</StyledTableRow>
										))}
										
									</TableBody>
						</Table>
			    </TabPanel>
				<TabPanel>
			      <Table>
									<TableHead>
									 	<StyledTableRow >
											<StyledTableCell align="left" colspan="2" className={classes.tableHeader}> 
											  	API Access details
											</StyledTableCell>
										</StyledTableRow >
									</TableHead>
									<TableBody>
										<StyledTableRow >
											<StyledTableCell align="left" width="50%">Merchant Id</StyledTableCell>
             								<StyledTableCell align="left">{this.state.merchant.id}</StyledTableCell>
										</StyledTableRow >
										<StyledTableRow>
											<StyledTableCell align="left">Signkey (Keep secure)</StyledTableCell>
             								<StyledTableCell align="left">
												<Box align="left">   
												{this.state.canModify? ( <>
													<Typography> {this.state.signkey} </Typography>
													<Button variant="contained" color="primary" onClick={this.generateNewSignkey} style={{marginTop: '1em', marginRight: '1em'}}>Generate</Button>
													<Button variant="contained" color="primary" onClick={this.updateSignkey} style={{marginTop: '1em'}}>Update</Button>
													</>
												) : (
													<Typography> You do not have permission to view this </Typography>
												)}
													
												</Box>												
											</StyledTableCell>
										</StyledTableRow >
										<StyledTableRow>
											<StyledTableCell align="left">API secret (Please note down and secure the generated value; If you log out or browser is closed the value will dissapear and you need to generate again, the user readable value is not persisted for security reasons.)</StyledTableCell>
             								<StyledTableCell align="left">												
												<Box align="left">
													<Typography> {this.state.newSecret} </Typography>
													<Button variant="contained" color="primary" onClick={this.generateSecret}  style={{marginTop: '1em', marginRight: '1em'}}>Generate</Button>
													<Button variant="contained" color="primary" onClick={this.updateSecretOnServer} style={{marginTop: '1em'}}>Update</Button>
												</Box>
											</StyledTableCell>												
										</StyledTableRow >
										<StyledTableRow >
										<StyledTableCell align="left">Merchant Integration API Manual</StyledTableCell>

										<StyledTableCell align="left">
											<Button href={apipdf} target="_blank"  variant="contained" color="primary" style={{marginTop: '1em', marginRight: '1em'}}> 
												View
											</Button>
										</StyledTableCell>																							
										</StyledTableRow >
									</TableBody>
						</Table>
			    </TabPanel>
			  </Tabs>
			  </form>			
			 }
		</Paper>
	);
  }
}

export default withStyles(useStyles)(Home)
