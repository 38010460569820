import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ApiService from '../ApiService/ApiService';
import Divider from '@material-ui/core/Divider';
import MaterialTable from 'material-table';
import {subMonths,subDays, format} from 'date-fns';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';

import MUIDataTable from "mui-datatables";
import PageTitle from "../../components/PageTitle/PageTitle";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker 
} from '@material-ui/pickers';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);


const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  detailhead: {
	 fontSize: 12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
	marginTop: 8,
    marginLeft: -65,
  },
});

class MerchantWireComponent extends Component {
	constructor(props) {
        super()
        this.state = {           
			fromDate: null,
			toDate: null,
			fromDateObj: null, 
			toDateObj: null,
			loading: false,
			failed: false,
			merchantTerminals: [],
			terminalId: null,
			wires: [],
			responsemessage: null,
			progressOpen: false,
			showCharges: false,
		}
		this.reloadData = this.reloadData.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
	reloadData() { 
		if(this.state.terminalId == null) {
			this.setState({responsemessage:"Please select Terminal to proceed",failed: true});
			return;
		}
		this.setState({failed: false, showCharges: true});
		this.fetchWires();
    }

	fetchMerchantTerminals() {
		
		ApiService.fetchMerchantTerminals()
			.then((res)=> {
				this.setState({merchantTerminals: res.data})			
			}).catch((e)=> {
				
		});
	}
	fetchWires() {
		ApiService.fetchWires(this.state.fromDate, this.state.toDate, this.state.terminalId, this.state.acquirerAccountId)
			.then((res)=> {
				this.setState({wires: res.data})
			})
	}
	showPdf(wireId) {
		ApiService.fetchWirePdf(wireId);
	}
	
	showXLS(wireId) {
		ApiService.fetchWireXls(wireId);
	}
	
 	componentDidMount() {
        this.fetchMerchantTerminals();
		console.log("terminal");
    }
	headCells2 = [
	  { id: 'ChargevName', numeric: false, label: 'ChargevName' }, 
	  { id: 'Value', numeric: true, label: 'Value' },
	  { id: 'Total', numeric: true, label: 'Total' }, 
	  { id: 'Direction', numeric: false, label: 'Direction' }, 
	];
	MenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
	      width: 380,
	    },
	  },
	};
	
	tableIcons = {
	    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
	};
	
	render(){
		const { classes } = this.props; 
		return (
			<React.Fragment>
				<Paper style={{margin: '1em', padding: '1em'}}>
						<PageTitle title="Merchant Wires" />
						<Divider light className={classes.searchbar}/>
						{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
						&nbsp;
					  <Grid container  spacing={1}>		
						  <Grid >
						  <MuiPickersUtilsProvider utils={DateFnsUtils} >
								  <DateTimePicker autoOk 
									label="From" ampm="false"								
									value={this.state.fromDateObj} 
									onChange={(val)=> this.setState({fromDateObj: val,fromDate: format(val,'yyyy-MM-dd')})} />
									&nbsp;	&nbsp;	&nbsp;
								 <DateTimePicker autoOk
							          label="To" ampm="false"						          
							          value={this.state.toDateObj}
							          onChange={(val) =>this.setState({toDateObj: val, toDate : format(val,'yyyy-MM-dd')})}
							        />
						  </MuiPickersUtilsProvider>
						 </Grid>
						 <Grid>
						   <FormControl className={classes.formControl}>
							  <InputLabel id="merchantTerminalSelectLabel">Terminals</InputLabel>
							  <Select value={this.state.merchantTerminalId}  MenuProps={this.MenuProps}
							  		onChange={this.handleChange} name="terminalId"
							  		labelId="merchantTerminalSelectLabel" id="merchantTerminalSelect"
			 					>
								<MenuItem key="" value="">&nbsp; </MenuItem>	
								 {	this.state.merchantTerminals && this.state.merchantTerminals.map((mt) =>
								  	 <MenuItem key={mt.terminalId} value={mt.terminalId} dense>{mt.terminalId+ "-" +mt.terminalCurrency + "-" + mt.terminalCardType}</MenuItem>
								)}
							  </Select>
							 </FormControl>
							
					 	 </Grid>
					</Grid>
					<Grid justify="center" alignItems="center" style={{marginTop: '2em'}} container xs={12}>
				  	  	 <Grid item xs={2}>
						 <Button
					        variant="contained"
					        color="primary"
					        size="small"
					        className={classes.button}
							onClick={this.reloadData}
					      >
					        Go
					    </Button>
	 					{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}
					    </Grid>
			  		</Grid>
					{this.state.showCharges &&
				<MUIDataTable
				  style={{margin: '2em 0 0 0'}}
			      title="Wires"
				  icons={this.tableIcons}
			      columns={[
			        { name: 'settlementAmount', label: 'Settle amt'},
					{ name: 'currency', label: 'Currency'},
			        { name: 'successTxnAmount' , label: 'Success amt'},
					{ name: 'totalCharges' , label: 'Charges'},
					{ name: 'paymentSettled' , label: 'Settled'},
					{ name: 'validFrom',label: 'From',
						customBodyRender: rowData => {
							return(
								<Typography noWrap="true" variant="caption">{format(new Date(rowData),'dd MMM yyyy') }</Typography> 
							);
						}
							
					},
					{ name: 'validTo',label: 'To',
						customBodyRender: rowData => {
							return(
								<Typography noWrap="true" variant="caption">{format(new Date(rowData),'dd MMM yyyy') }</Typography>
							);
						}
					},
					{ name: 'approved', label: 'Approved'},					
			      ]}
			      data={this.state.wires}
				  actions={[					
					{					
						tooltip: 'Report PDF',
						icon: (props)=> <PictureAsPdfIcon {...props} />,
						onClick: (evt, rowData) => this.showPdf(rowData.wireId),
					},
					{
						tooltip: 'Transactions XLS',
						icon: (props)=> <Avatar variant='square' className={classes.small} src='xls-icon.png' {...props} />,
						onClick: (evt, rowData) => this.showXLS(rowData.wireId),
					}
					
						
				  ]}
				  options={{
					search:false,
			        exportButton: false,
					pageSize: 10,
					pageSizeOptions: [10,20,30],
					padding: 'default',
					showEmptyDataSourceMessage: true,
					selection: false,
					actionsColumnIndex: -1,
					tableLayout: 'auto',
					emptyRowsWhenPaging: false,
			      }}
					
				   detailPanel={[
			        {
			          tooltip: 'Show details',
			          render: rowData => {						
			            return (
							<Table size="small" >
							 <TableHead>
								<TableRow className={classes.detailhead}>
									{this.headCells2.map((head) => (
										<StyledTableCell 
								            key={head.id}
								            align='left'  
								          > 
											{head.label}
						         		 </StyledTableCell>
									))}
								</TableRow>
							 </TableHead>
							<TableBody>
							{rowData.wireDetails.map((txn,index) => 
								<TableRow key={index}>
									<TableCell align="left">{txn.chargeName}</TableCell>
									<TableCell align="left">{txn.chargeValue}</TableCell>
									<TableCell align="left">{txn.amount}</TableCell>
									<TableCell align="left">{txn.chargeDirection}</TableCell>
								</TableRow>
							)}								
							</TableBody>							
						   </Table>
					     )
			          },
			         },
				    ]}    
			    />
				}
				</Paper>
			</React.Fragment>
		);
	}
}
export default withStyles(useStyles)(MerchantWireComponent);