import React, { useState } from "react";
import { TextField, Button , Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import { useForm } from "react-hook-form";

// styles
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";

//components
import { Avatar, Grid, Paper, CircularProgress,Backdrop } from '@material-ui/core/';
import MUIDataTable from "mui-datatables";

//pages
import ApiService from '../ApiService/ApiService';

//data
import mock from "./mock";


export default function StoreManagement(props) {
  var classes = useStyles();
  var theme = useTheme();

  const [merchant, setMerchant] = useState([]);
  const [store, setStore] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [storeLocation, setstoreLocation] = useState("");
  const [message, setMessage] = useState("");

  const { register, handleSubmit, formState: { errors } } = useForm();

  React.useEffect(() => {
    fetchBusinessDetails();          
  },[]);

  const fetchStoreDetails = (() => {    

    ApiService.fetchStores(merchant.id).then((res) => {
      setStore(res.data);               
    }); 

  });


  const fetchBusinessDetails = (() => {    

    ApiService.getUserMerchant().then((res) => {
        setMerchant(res.data);               
    }); 
       

  });

  const addNewStore = (() => {
    ApiService.addNewStore(merchant.id,storeName, storeLocation).then((res) => {
      setMessage("Success");
    }).catch(error => {
      setMessage("Failure");
    });

    //fetchStoreDetails();
   
  });

  return (
    <>
      
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}        
        autoComplete="off"
      >
        <TextField name="storeName" type="text" label="Store Name" variant="outlined" 
          className="input-field"
          {...register('storeName', { required: true })}
          onChange={(e) => {setStoreName(e.target.value); setMessage("");  }}
        />
         {errors.storeName && errors.storeName.message}
      

        <TextField name="storeLocation" id="outlined-basic" label="Store Location" variant="outlined"
         type="text"
         className="input-field"
         {...register('storeLocation', { required: true })}
         onChange={(e) => {
              setstoreLocation(e.target.value);
              setMessage("");
        }}/>     
        {errors.storeLocation && errors.storeLocation.storeLocation}         
     
        <Button variant="contained" color="primary" onClick={addNewStore} 
        style={{width:300, height:42, marginLeft:10 ,marginTop: '1em', marginRight: '1em'}}
        disabled={
          storeName.length === 0 || 
          storeLocation.length === 0                    
                               
        }> Add Store</Button>
        <Typography weight="medium" style={{color:"#009933"}}>{message}</Typography>
        
      </Box>
      
      <Grid item xs={12}>
      
                <MUIDataTable
                columns={[ 
                  {name:'storeName', label:'storeName'},
                  {name:'storeLocation', label:'storeLocation'},
                  
                ]}                
                  title="Latest Transactions"                 
                  data={store}
                  actions={[
						
						
                  ]}
                  options={{             
                    selectableRowsHideCheckboxes: true,		
                  }}
                 />
                 <br/>
                 <br/>
                 <center>
                 <Button variant="contained" color="primary" onClick={fetchStoreDetails}>Show Stores</Button>
                 </center>
                 
                 
              </Grid>     

    </>
  );
}
