import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'
import { useTheme } from "@material-ui/styles";

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Home from "../../pages/Home/Home";
import QuickPay from "../../pages/quickpay/quickPay";
import Password from "../../pages/Password/Password";
import Wire from "../../pages/Wire/Wire";
import Terminal from "../../pages/Terminal/Terminal";
import Security from "../../pages/security/Security";
import Transactions from "../../pages/Transactions/Transactions";
import fraudRules from "../../pages/FraudRules/fraudRules";
import Notification from "../../pages/report/Notification";
import StoreManagement from "../../pages/StoreManagement/StoreManagement";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}           
          >
            <div className={classes.fakeToolbar} />            
            <Switch>
              <Route path="/app/home" component={Home} />
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/quickpay" component={QuickPay} />
              <Route path="/app/wire" component={Wire} />
              <Route path="/app/txn" component={Transactions} />     
              <Route path="/app/report" component={Notification} />                            
              <Route path="/app/security" component={Security} />                            
              <Route path="/app/password" component={Password} />                            
              <Route path="/app/terminal" component={Terminal} />
              <Route path="/app/store" component={StoreManagement} />
              <Route path="/app/fraudmanagement" component={fraudRules} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/notifications" component={Notifications} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>         
          </div>                          
        </>
    </div>
  );
}

export default withRouter(Layout);
