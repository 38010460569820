import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    card: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    fullHeightBody: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between",
    },

    model:{
      color:'#0000b3'  ,
    },

    success:{
        color:'#66ff1a',
    
    },

    failure:{
        color:'#e60000',
    }

}));