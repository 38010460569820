import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip
} from "@material-ui/core";
import useStyles from "../../styles";

const states = {
  sent: "success",
  pending: "warning",
  declined: "secondary",
};

export default function TableComponent({ data }) {
  const classes = useStyles();
  var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {keys.map(key => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ id, name, date,terminal, total,payment_status, payment_method,payment_type }) => (
          <TableRow key={id}>
            <TableCell className="pl-3 fw-normal">{date}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{terminal}</TableCell>
            <TableCell>{total}</TableCell>
            <TableCell>{payment_status}</TableCell>
            <TableCell>{payment_method}</TableCell>
            <TableCell>{payment_type}</TableCell>
            
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
