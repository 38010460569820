import React, { Component } from 'react';
import { Container, Card, CardContent, CardHeader, Divider, Grid, InputLabel, Select, Typography, MenuItem, Button, Box, FormControl, Input, FormControlLabel, Checkbox,TextField, FormHelperText } from '@material-ui/core';
import ApiService from '../ApiService/ApiService';
import Alert from '@material-ui/lab/Alert';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow  from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { withStyles, styled, tableCellClasses  } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = theme => ({
	topCard: {
		marginTop: theme.spacing(1),
		minHeight: '100vh',
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10),
		marginBottom: 10,
	},
	tableHeader: {
	 backgroundColor: theme.palette.common.black,
	 color: theme.palette.common.white,
 },
  tableRowDark: {
	 backgroundColor: theme.palette.action.hover,
	 color: theme.palette.common.white,
 },
});

const validEmailRegex = RegExp(
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const validateForm = errors => {
	let valid = true;
	Object.values(errors).forEach(val => val.length > 0 && (valid = false));
	return valid;
  };

class AmendUserForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userId: '',
			userName: '',  
			email: '',
			firstName: '',
			lastName: '',
			phone: '',			
			city: '',
			address: '',
			state: '',
			country: '',
			zip: '',
			responseMessage: null,
			failed: false,
			success: false, 
			editingUser: false,
			errors: {
				email: '',
				phone: '',
				country: '',
			}
		}
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this); 
		this.startEditUser = this.startEditUser.bind(this);
		this.stopEditUser = this.stopEditUser.bind(this);
		this.fetchUser = this.fetchUser.bind(this);
	}
	componentDidMount() {
		this.fetchUser();
	}
	handleInputChange(event) {

		event.preventDefault();
		const { name, value } = event.target;
		let errors = this.state.errors;	

		switch (name) {
			case 'phone': 
				errors.phone = 
				isNaN(value) || value.length != 10
					? 'Phone number not valid!'
					: '';			
				break;
			case 'email': 
				errors.email = 
				validEmailRegex.test(value)
					? ''
					: 'Email is not valid!';
				break;
			case 'country': 
				errors.country = 
				value.length < 2
					? 'Country should only have 2 chars'
					: '';
				break;
			default:
				break;
		}

		this.setState({errors, [name]: value});

		// this.setState(
        //     {
        //         [event.target.name]
        //             : event.target.value
        //     }
        // )
	}
	   
	handleSubmit() {		

		if(validateForm(this.state.errors)) {
			
			//console.info('Valid Form')
			var request = Object.assign({}, this.state);
				ApiService.updateUser(request).then((res)=>{
				if(res.data.status === 'OK') {
					this.setState({success: true, failed: false, responseMessage: res.data.responseMessage});
					this.stopEditUser(); 
				} else {
					this.setState({success: false, failed: true, responseMessage: res.data.responseMessage});
				}
			}) 

		  }else{

			this.setState({success: false, failed: true, responseMessage: 'Invalid Form'});
			//console.error('Invalid Form')

		  }		
	}

	fetchUser() {
		ApiService.getCurrentUser().then((res) => {
			this.setState({userId: res.data.userId!=null?res.data.userId:'',
			userName: res.data.userName!=null?res.data.userName:'',  
			email: res.data.email!=null?res.data.email:'',
			firstName: res.data.firstName!=null?res.data.firstName:'',
			lastName: res.data.lastName!=null?res.data.lastName:'',
			address:res.data.address!=null?res.data.address:'',
			phone: res.data.phone!=null?res.data.phone:'',
			city: res.data.city!=null?res.data.city:'',
			state: res.data.state!=null?res.data.state:'',
			country: res.data.country!=null?res.data.country:'',
			zip: res.data.zip!=null?res.data.zip:'',})
		})
	}

	startEditUser() {
		this.setState({editingUser: true});	
	}	
	stopEditUser(){
		this.fetchUser();
		this.setState({editingUser: false});
	}

	render() {
		const { classes } = this.props; 
		const {errors} = this.state;
	 
		return (
			<>
		 	 {this.state.failed && <Alert severity="error" elevation="0"> {this.state.responseMessage}</Alert>}
						{this.state.success && <Alert severity="success" elevation="0">{this.state.responseMessage}</Alert>}
				<Table>
				<TableHead> 
					 	<TableRow>
							<TableCell width="30%" align="left" className={classes.tableHeader}>User Details</TableCell>
							<TableCell align="right" className={classes.tableHeader}>
							    {this.state.editingUser? 
									<Button variant="contained" onClick={this.stopEditUser} color="primary">Cancel</Button>
								:
									<Button variant="contained" onClick={this.startEditUser} startIcon={<EditIcon />} color="primary">Edit</Button>
								} 
							</TableCell>
						</TableRow >
				 </TableHead>  
				 <TableBody>
						<TableRow>
							<TableCell align="left">User name</TableCell>
							<TableCell align="left"> 
								 {this.state.userName}  
							</TableCell>
						</TableRow >
						<TableRow  className={classes.tableRowDark}>
							<TableCell align="left">First name</TableCell>
							<TableCell align="left">
							   {this.state.editingUser == true && 									
									<TextField value={this.state.firstName} placeholder="First Name" name="firstName" onChange={this.handleInputChange}/> 									 
								}
								 {this.state.editingUser == false && 
									<>{this.state.firstName}</>
								} 
								</TableCell>
						</TableRow >
						<TableRow >
							<TableCell align="left">Last name</TableCell>
							<TableCell align="left">
							   {this.state.editingUser?								 
									<TextField value={this.state.lastName} placeholder="Last Name" name="lastName" onChange={this.handleInputChange}/>  								
								:
									<>{this.state.lastName}</>
								}
							</TableCell>
						</TableRow >
						<TableRow  className={classes.tableRowDark}>
							<TableCell align="left">Contact Email</TableCell>
							<TableCell align="left">
							   {this.state.editingUser?

									<TextField name="email" placeholder="Email" error={this.state.email===null} margin="dense" type="email"
									value={this.state.email} required helperText={this.state.email==null && "Value is required"} onChange={this.handleInputChange}/>							
								:
									<>{this.state.email}</>
								}
								{errors.email.length > 0 && 
                				<span className='error'>{errors.email}</span>}
							</TableCell>
						</TableRow >
						<TableRow >
							<TableCell align="left">Address</TableCell>
							<TableCell align="left">
								{this.state.editingUser?
									
										<TextField value={this.state.address} name="address" onChange={this.handleInputChange} placeholder="Address" />
									
								:
									<>{this.state.address}</>
								}
	
							</TableCell>
						</TableRow >
						<TableRow  className={classes.tableRowDark}>
							<TableCell align="left">City</TableCell>
							<TableCell align="left">
								{this.state.editingUser? 
								
									<TextField value={this.state.city} placeholder="City" name="city" onChange={this.handleInputChange} />
								 
								:
									<>{this.state.city}</>
								}
							</TableCell>
							
						</TableRow >
						<TableRow >
							<TableCell align="left">Country</TableCell>
							<TableCell align="left">
								{this.state.editingUser?
								 
									<TextField value={this.state.country} name="country" onChange={this.handleInputChange} placeholder="Country (Only 2 char code)"/>
									 
								: 
									<>{this.state.country}</>
								}
								{errors.country.length > 0 && 
                				<span className='error'>{errors.country}</span>}
							</TableCell>
						</TableRow >
						<TableRow  className={classes.tableRowDark}>
							<TableCell align="left">Zip</TableCell>
							<TableCell align="left">
								{this.state.editingUser?
																											
									<TextField value={this.state.zip} name="zip" onChange={this.handleInputChange} placeholder="Zip"/>
									
								:
									<>  {this.state.zip}</>
								}	
							</TableCell>
						</TableRow >
						<TableRow >
							<TableCell align="left">Contact Telephone</TableCell>
							<TableCell align="left"> 
							{this.state.editingUser?
																	
								<TextField value={this.state.phone} name="phone" onChange={this.handleInputChange} placeholder="Phone"/>
								
							:
								<>{this.state.phone}</>
							} 
							{errors.phone.length > 0 && 
                				<span className='error'>{errors.phone}</span>}
							</TableCell>														 
						</TableRow >
						{this.state.editingUser &&
						<TableRow >
							<TableCell colSpan="2" align="center"><Button variant="contained" onClick={this.handleSubmit}  color="primary">Update</Button></TableCell>
						</TableRow>
						}
					</TableBody>
				 </Table>				    
			  </>
		);
	}
}
export default withStyles(useStyles)(AmendUserForm)