import React from "react";
import Box from '@mui/material/Box';

// styles
import useStyles from "./styles";

//components
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Widget from "../../components/Widget";
import { Avatar, Grid, Paper, CircularProgress,Backdrop } from '@material-ui/core/';
import Table from "./components/Table/Table";
import MUIDataTable from "mui-datatables";

//pages
import ApiService from '../ApiService/ApiService';
import PageTitle from "../../components/PageTitle/PageTitle";

//data
import mock from "./mock";

export default function Security() {
    var classes = useStyles();

    const [value, setValue] = React.useState('1');
    const [rules, setRules] = React.useState([]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
      //fetchTerminal(merchant.id);
    };

    React.useEffect(() => {

      showFraudRules();   
     
    },[]);

    const data = [
      { name: 'Store 1', x: 12, y: 23, z: 122 },
      { name: 'Store 2', x: 22, y: 3, z: 73 },
      { name: 'Store 3', x: 13, y: 15, z: 32 },
      { name: 'Store 4', x: 44, y: 35, z: 23 },
      { name: 'Store 5', x: 35, y: 45, z: 20 },
      { name: 'Store 6', x: 62, y: 25, z: 29 },
      { name: 'Store 7', x: 37, y: 17, z: 61 },
      { name: 'Store 8', x: 28, y: 32, z: 45 },
      { name: 'Store 9', x: 19, y: 43, z: 93 },
    ];

    const showFraudRules = (() => {    

      ApiService.fetchFraudRules().then((res)=> {
          //this.setState({rules: res.data,terminalPanelVisible: false})			
          setRules(res.data);

      });		
  
    });

  return (
    <>
    <PageTitle title="Security Settings" />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="On Hold Transactions" value="1" />
                <Tab label="Transaction Review" value="2" />
                <Tab label="Processing Rules" value="3" />
                <Tab label="Card Amount Limit" value="4" />
                <Tab label="Card Filter" value="5" />
                <Tab label="Country Controls" value="6" />
                <Tab label="Email Notifications" value="7" />
                <Tab label="Transaction advice" value="8" />
               
            </TabList>
            </Box>
            <TabPanel value="1">  
            <Grid item xs={12}>
                <Widget
                  title="Latest Transactions"
                  upperTitle
                  noBodyPadding
                  disableWidgetMenu={true}
                  bodyClass={classes.tableWidget}
                >
                  <Table data={mock.onHoldTable} />
                </Widget>
              </Grid>                       
            </TabPanel>

            <TabPanel value="2">  
              <Grid item xs={12}>
                
                <MUIDataTable
                columns={[ 
                  {name:'id', label:'Id'},
                  {name:'date', label:'Date'},
                  {name:'name', label:'Name'},
                  {name:'terminal', label:'Terminal'},
                  {name:'total', label:'Total'},
                  {name:'payment_status', label:'Payment Status'},
                  {name:'payment_method', label:'Payment Method'},
                ]}                
                  title="Latest Transactions"                 
                  data={mock.table}
                 />
              </Grid>      
            </TabPanel>

            <TabPanel value="3">   
              <Grid item xs={12}>
               
                 <MUIDataTable
                  title="Rules"
                  columns={[ 
                    {name:'fraudName', label:'Fraud Name'},
                    {name:'fraudDescription', label:'Fraud Description'},
                  ]}
                  data={rules}
                 />
           
              </Grid>                          
            </TabPanel>

            <TabPanel value="4">  
            Card Amount Limit                          
            </TabPanel>

            <TabPanel value="5">  
            Card Filter                          
            </TabPanel>

            <TabPanel value="6">  
            Country Controls                          
            </TabPanel>

            <TabPanel value="7">                            
            Email Notifications
            </TabPanel>

            <TabPanel value="8">  
            Transaction advice                          
            </TabPanel>
        </TabContext>
        </Box>
    </>
  );
}
