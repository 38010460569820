import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import mock from "../dashboard/mock";
import { Avatar, Grid, Button,  CardHeader, Card, CardContent, CardActions,CardActionArea, CardMedia,  Paper, CircularProgress,Backdrop } from '@material-ui/core/';
import Widget from "../../components/Widget";
import fetchMerchantTransactions from "../ApiService/ApiService";



import useStyles from "../dashboard/styles";
import TableNew from '../dashboard/components/Table/TableNew';
import Table from "../dashboard/components/Table/Table";
import TerminalTable from '../dashboard/components/Table/TerminalTable';
import StoreTable from '../dashboard/components/Table/StoreTable';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import ApiService from '../ApiService/ApiService';
import PageTitle from "../../components/PageTitle/PageTitle";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}





TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}







export default function NotificationsPage(props) {
  var classes = useStyles();

  // const Details =()=>{
  //   useEffect(()=>{
  //     getChargeBackDetails();
  //   },[])
  // }

  // const[details,setDetails]=useState();

  // const getChargeBackDetails = ()=>{



  //   axios.get(ApiService.fetchMerchantTerminals()).then((res) =>{
  //    setDetails(res.data);
  //    console.log(details);
  //   }
  //   )
  //   .catch((err)=>console.error(err));
  // }

  
  
  


  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // var classes = useStyles();
  
  return (
    <>

<PageTitle title="Reports" />

<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Transactions List" {...a11yProps(0)} />
          <Tab label=" Chargeback List" {...a11yProps(1)} />
          <Tab label=" Fraud Txn List" {...a11yProps(2)} />
          <Tab label="Wire List" {...a11yProps(3)} />
          <Tab label="Terminal Lists" {...a11yProps(4)} />
          <Tab label="Users List" {...a11yProps(5)} />
          <Tab label=" Store List" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
     

      <Grid item xs={12}>
          <Widget
            title=" Transactions List"
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <Table data={mock.table} />
          </Widget>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
          <Grid item xs={12}>
                <Widget
                  title="Chargeback Transactions"
                  upperTitle
                  noBodyPadding
                  disableWidgetMenu={true}
                  bodyClass={classes.tableWidget}
                >
                  <Table data={mock.chargebacktable} />
                </Widget>
              </Grid>     

      </TabPanel>
      <TabPanel value={value} index={2}>
      <Grid item xs={12}>
                <Widget
                  title="Fraud Transactions"
                  upperTitle
                  noBodyPadding
                  disableWidgetMenu={true}
                  bodyClass={classes.tableWidget}
                >
                  <Table data={mock.fraudtable} />
                </Widget>
              </Grid>     

      </TabPanel>

      <TabPanel value={value} index={3}>
      Wire List
      </TabPanel>

      <TabPanel value={value} index={4}>
      

      <Grid item xs={12}>
          <Widget
            title="Terminal List"
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <TerminalTable data={mock.terminals} />
          </Widget>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={5}>

      <Grid item xs={12}>
          <Widget
            title="Users List"
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <TableNew data={mock.users} />
          </Widget>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={6}>

      <Grid item xs={12}>
          <Widget
            title="Store List"
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <StoreTable data={mock.stores} />
          </Widget>
        </Grid>
      </TabPanel>

      
    </Box>
  
{/* {'}'} */}







   

      
    </>
  );

  // #############################################################
//   function sendNotification(componentProps, options) {
//     return toast(
//       <Notification
//         {...componentProps}
//         className={classes.notificationComponent}
//       />,
//       options,
//     );
//   }

//   function retryErrorNotification() {
//     var componentProps = {
//       type: "message",
//       message: "Message was sent successfully!",
//       variant: "contained",
//       color: "success",
//     };
//     toast.update(errorToastId, {
//       render: <Notification {...componentProps} />,
//       type: "success",
//     });
//     setErrorToastId(null);
//   }

//   function handleNotificationCall(notificationType) {
//     var componentProps;

//     if (errorToastId && notificationType === "error") return;

//     switch (notificationType) {
//       case "info":
//         componentProps = {
//           type: "feedback",
//           message: "New user feedback received",
//           variant: "contained",
//           color: "primary",
//         };
//         break;
//       case "error":
//         componentProps = {
//           type: "message",
//           message: "Message was not sent!",
//           variant: "contained",
//           color: "secondary",
//           extraButton: "Resend",
//           extraButtonClick: retryErrorNotification,
//         };
//         break;
//       default:
//         componentProps = {
//           type: "shipped",
//           message: "The item was shipped",
//           variant: "contained",
//           color: "success",
//         };
//     }

//     var toastId = sendNotification(componentProps, {
//       type: notificationType,
//       position: positions[notificationsPosition],
//       progressClassName: classes.progress,
//       onClose: notificationType === "error" && (() => setErrorToastId(null)),
//       className: classes.notification,
//     });

//     if (notificationType === "error") setErrorToastId(toastId);
//   }

//   function changeNotificationPosition(positionId) {
//     setNotificationPosition(positionId);
//   }
// }

// // #############################################################
// function CloseButton({ closeToast, className }) {
//   return <CloseIcon className={className} onClick={closeToast} />;
// 
      }
