import React from 'react'
import { Doughnut, defaults } from 'react-chartjs-2';
import { ArcElement } from "chart.js";


const DoughnutChart = (props) => {
			const pieData = [];
			 
			if(props.txnSummary!= null ) {
				var total = props.txnSummary.successfulTxnAmount 
					+ props.txnSummary.declinedTxnAmount 
					+ props.txnSummary.refundTxnAmount 
					+ props.txnSummary.chbkTxnAmount 
					+ props.txnSummary.successfulSettledAmount;
			 
				if(props.txnSummary.successfulTxnAmount != 0)
					pieData.push((100 / (total/props.txnSummary.successfulTxnAmount)).toFixed(2));
				else
					pieData.push(0)	
				
				if(props.txnSummary.successfulSettledCount != 0)
					pieData.push((100 /(total/props.txnSummary.successfulSettledAmount)).toFixed(2) );
				else
					pieData.push(0)	
					
				if(props.txnSummary.declinedTxnAmount != 0)
					pieData.push((100 /(total/props.txnSummary.declinedTxnAmount)).toFixed(2) );
				else 
					pieData.push(0)
					
				if(props.txnSummary.chbkTxnCount != 0)	
					pieData.push((100 /(total/props.txnSummary.chbkTxnAmount)).toFixed(2));
				else 
					pieData.push(0)
					
				if(props.txnSummary.refundTxnAmount != 0)	
					pieData.push((100 /(total/props.txnSummary.refundTxnAmount)).toFixed(2));
				else 
					pieData.push(0)
			}  
			
  return (
    

    <div>
      <Doughnut
      
        data={{
         
          labels: ['Succesful', 'Settled', 'Declined', 'Chargebacks', 'Refunds'],
          datasets: [
            {
              
              label: 'Count',
              data: pieData,
              backgroundColor: [
                'rgb(99, 117, 98)',
				'rgb(173, 206, 171)',
                'rgb(35, 75, 119)',
                'rgb(153, 65, 65)',
                'rgba(239, 192, 4, 0.91)',
              ],
              borderColor: [
                'black',
				'black',
                'black',
                'black',
                'black',
                
              ],
              borderWidth: 1,
              
            },
          ],
        }}
        height={300}
        width={420}
        options={{
          maintainAspectRatio: false,
          
          scales: {
            xAxes: [{
              barThickness: 50,
              maxBarThickness: 50,
              width:50
            }],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            position: 'bottom',
            
            labels: {
             
              fontSize: 25,
            },
          },
        }}
      />
    </div>
  )
}

export default DoughnutChart