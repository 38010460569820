import axios from 'axios';
import {saveAs} from 'file-saver';

const API_URL = process.env.REACT_APP_HOST_URL;
const USER_API_BASE_URL = API_URL + '/reports/';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const MERCHANT_SESSION_ATTRIBUTE_NAME = 'loggedInMerchant'

class ApiService {

    fetchMerchants() {
        return axios.get(USER_API_BASE_URL + 'merchants');
    }

    fetchMerchantById(merchantId) {
        return axios.get(USER_API_BASE_URL + 'merchant?merchantId=' + merchantId);
    }

	fetchMerchantByName(merchantName) {
        return axios.get(USER_API_BASE_URL + 'merchantByName?merchantName=' + merchantName);
    }

	fetchLoggedInMerchant() {
        return this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME);
    }
	registerLoggedInMerchant(merchant) {
		this.setInSessionStorage(MERCHANT_SESSION_ATTRIBUTE_NAME, merchant)
	}
	
	createUser(formData) {
	   return axios.post( API_URL + '/user/createUser',  formData);
	}
	
	updateUser(userObj) {
		 return axios.post( API_URL + '/user/updateUser',  {
			"request": userObj,
		});
	}
	updateUserMerchant(userMerObj) {
		 return axios.post( API_URL + '/user/updateUserMerchant',  {
			"merchant": userMerObj,
		});
	}
	
	getCurrentUser() {
		return axios.get(API_URL + '/user/getCurrentUser');
	}
	getUserDocuments() {
		return axios.get(API_URL + '/user/getUserDocuments');
	}
	
	onbboardBusinessDetails(merchant) {
		return axios({method: 'post', url: API_URL + '/user/onbboardBusinessStep', data : merchant});
	}
	
	
	getUserMerchant() {
		return axios.get(API_URL + '/user/getUserMerchant');
	}
	
	getMerchantDocumentConfig() {
		return axios.get(API_URL + '/user/getMerchantDocumentConfig');
	}
	
	getUserMerchantDocuments(formData) {
		return axios.get(API_URL + '/user/getMerchantDocumentConfig', formData);
	}
	
	uploadOnboardingDocs(formData) {
		return axios.post(API_URL + '/user/uploadOnboardingDocs', formData);
	}
	updateMerchantSecret(merchantId, val) {
		return axios.post(API_URL + '/user/updateMerchantSecret', {
			"id" : merchantId,
			"password": val,
		});
	} 
	updateMerchantSignkey(merchantId, val) {
		return axios.post(API_URL + '/user/updateMerchantSignkey', {
			"id" : merchantId,
			"signKey": val,
		});
	} 
	fetchTransactionsXL(fromDate, toDate, statuses, orderNo, email, allChbk) {
		axios({
			method: 'post',
			url: API_URL + '/reports/fetchTransactionXL',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "fromDate" : fromDate,
		  		 "toDate" : toDate,
		  		 "statuses": statuses,
	 		  	 "orderNo" : orderNo,
				 "email" : email,
				 "allChbk" : allChbk,
			}
		})
		.then(response => {
		//Create a Blob from the XLS Stream
		    saveAs(new Blob(
		      [response.data], 
		      {type: 'application/vnd.ms-excel'}),'transactions.xlsx');
		//Build a URL from the file
		 //   const fileURL = URL.createObjectURL(file);
		//Open the URL on new Windowupl
		  //  window.open(fileURL + ".xlsx");
		})
		.catch(error => {
		    console.log(error);
		}); 
	}
	
	fetchMerchantTransactions(fromDate, toDate, statuses, orderNo, email, pageNumber, numberRowPerPage, allChbk) {
		return axios.post( API_URL + '/reports/merchantTransactions', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
          "fromDate" : fromDate,
		  "toDate" : toDate,
		  "statuses": statuses,
		  "orderNo" : orderNo,
		  "email" : email,
		  "pageNumber" : pageNumber,
		  "numberRowPerPage" : numberRowPerPage,
		  "allChbk" : allChbk,
      })
	}
	fetchMerchantTerminals(){
		return axios.post( API_URL + '/wire/fetchMerchantTerminals', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}

	fetchFraudRulesByMerchantId(merId) {
		return axios.post( API_URL + '/wire/fetchFraudRulesById', {	
			"merchantId":merId,	  
        })
	}

	fetchFraudRules() {
		return axios.post( API_URL + '/wire/fetchFraudRules', {		  
        })
	}	

	addFraduRule(fraudName,merId,fraudValue) {
		return axios.post( API_URL + '/wire/addFraudRulesById', {		
		  "merchantId":merId,		
		  "fraudName":fraudName,
		  "fraudValue":fraudValue,
        })
	}

	//Terminals
	fetchTerminals(merId,location,status){
		//console.log(this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME));
		return axios.post( API_URL + '/reports/fetchTerminals', {     					
			"merchantId" : merId,
			"terminalLocation" : location,
			"terminalStatus" : status,
        })
	}

	fetchTerminalsStatus(merId,status){
		//console.log(this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME));
		return axios.post( API_URL + '/reports/fetchTerminalsStatus', {     					
			"merchantId" : merId,
			"terminalStatus" : status,
        })
	}

	//fetch Stores
	fetchStores(merchantId){
		return axios.post( API_URL + '/reports/fetchStores', {
          "merchantId" : merchantId,		  
        })
	}

	//insert store
	addNewStore(merchantId,storeName, storeLocation) {
		return axios.post(API_URL + '/reports/addNewStore', {
			"merchantId" : merchantId,
			"storeName": storeName,
			"storeLocation":storeLocation,
		});
	} 


	fetchAcquirerAccounts(){
		return axios.post( API_URL + '/wire/fetchAcquirerAccounts', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}

	fetchMasterCharges(){
		return axios.post( API_URL + '/wire/fetchMasterCharges', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	fetchEntityChargeDetails(fromDate, toDate, terminalId, accountId) {
		return axios.post( API_URL + '/wire/fetchEntityChargeDetails', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
          "validFrom" : fromDate,
		  "validTo" : toDate,
		  "enitityId": terminalId!=null?terminalId:accountId,
		  "entityType" : "MERCHANT",
      })
	}
	
	acquirer023Notification(status, order_id, transactionId, message, errorCode) {
		return axios.post( API_URL + '/res/ACQ023Notification', {
          "status" : status,
          "orderId" : order_id,
		  "transactionId" : transactionId,
		  "message" : message,
		  "errorCode": errorCode,
      })
	}
	
	fetchWires(fromDate, toDate, terminalId, accountId) {
		return axios.post( API_URL + '/wire/fetchWires', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
          "fromDate" : fromDate,
		  "toDate" : toDate,
		  "enitityId": terminalId,
		  "entityType" : "MERCHANT",
      })
	}
	changePassword(currentPass, newPass, confirmPass, passType) {
		return axios.post( API_URL + '/reports/changePassword', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"currentPass" : currentPass,
			"newPass" : newPass,
			"confirmPass" : confirmPass,
			"passType": passType,
		})
	}
	
	getFromSessionStorate(key) {
		return sessionStorage.getItem(key);
	}
	setInSessionStorage(key, value) {
		sessionStorage.setItem(key, value);	
	}
	removeFromSessionStorage(key) {
		sessionStorage.removeItem(key);
	}
    executeJwtAuthenticationService(username, password) {
      return axios.post( API_URL + '/txn/AuthToken' , {
          "username" : username,
          "secret" : password,
		  "source" : "dashboard" 	
      })
  }
  
  registerSuccessfulLogin(username, password) {		
        this.setInSessionStorage(USER_NAME_SESSION_ATTRIBUTE_NAME, username);		
        this.setupAxiosInterceptors(this.createBasicAuthToken(username, password));
    }

    registerSuccessfulLoginForJwt(username, token) {
        this.setInSessionStorage(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        this.setupAxiosInterceptors(this.createJWTToken(token))
    }

    createJWTToken(token) {
        return token
    }


    logout() {
		//sessionStorage.clear();
        this.removeFromSessionStorage(USER_NAME_SESSION_ATTRIBUTE_NAME);
		this.removeFromSessionStorage(MERCHANT_SESSION_ATTRIBUTE_NAME);
    }

    isUserLoggedIn() {
        let user = this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let user = this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

	refundMerchantTransaction(transactionId, reason, refundAmount) {
		 return axios.post( API_URL + '/reports/refundMerchantTransaction', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"merchantTransactionId" : transactionId,
			"reason" : reason,
			"refundAmount" : refundAmount,			
		})
	}
	
    setupAxiosInterceptors(token) {
        axios.interceptors.request.use(
            (config) => {
                if (this.isUserLoggedIn()) {
                    config.headers.authorization = token
                }
                return config
            }
        )
    }

	

fetchWirePdf(wireId) {
		axios({
			method: 'post',
			url: API_URL + '/wire/fetchWirePdf',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "wireId":wireId,
			}
		})
		.then(response => {
		//Create a Blob from the PDF Stream
		    const file = new Blob(
		      [response.data], 
		      {type: 'application/pdf'});
		//Build a URL from the file
		    const fileURL = URL.createObjectURL(file);
		//Open the URL on new Windowupl
		    window.open(fileURL);
		})
		.catch(error => {
		    console.log(error);
		}); 
	}
	
	fetchWireXls(wireId) {
		axios({
			method: 'post',
			url: API_URL + '/wire/fetchWireXls',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "wireId":wireId,
			}
		})
		.then(response => {
		//Create a Blob from the PDF Stream
		    const file = new Blob(
		      [response.data], 
		      {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
		//Build a URL from the file
		    const fileURL = URL.createObjectURL(file);
		//Open the URL on new Windowupl
		    window.open(fileURL);
		})
		.catch(error => {
		    console.log(error);
		}); 
		
	}
	checkAccessByMenuAndAction(menuText, action) { 
		return axios.post(API_URL + '/admfns/checkAccessByMenuAndAction', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			 "menuText" : menuText,
			 "action" : action,
		})
	}
	fetchMerchantTxnStatusSummary() {
		return axios.post(API_URL+'/admfns/fetchMerchantTxnStatusSummary', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	
	fetchMonthsMerchantTxnSummary() {
		return axios.post(API_URL+'/admfns/fetchMonthsMerchantTxnSummary', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	fetchFraudInfoForTxn(txnId) {
		return axios.post(API_URL + '/admfns/fetchFraudInfoForTxn', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			 "txn_id" : txnId,
		})
	}
	userMenuMappings(userId) {
		return axios.post(API_URL + '/admfns/userMenuMappings', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			 "userId" : userId,
		})
	}
	
	fetchQuickPayments() {
		//console.log(this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME));
		return axios.post(API_URL + '/qpay/fetchQuickPayments', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME)
		})
	}
	
	createQuickPayment(terminalId, amount, remarks) {
		return axios.post(API_URL + '/qpay/createQuickPayment', { 
			"terminalId" : terminalId,
			"amount": amount,
			"remarks" : remarks,
		});
	}
	
	cancelQuickPayment(qpId) {
		return axios.post(API_URL + '/qpay/cancelQuickPayment', {
			"qpId" : qpId,
		});
	}
	
	quickPaymentCheckout(data) {
		return axios.post(API_URL + '/res/quickPaymentCheckout',  data);
	}
	
	retrieveQuickPayTransaction(qpId) {
		return axios.post(API_URL + '/res/retrieveQPTransaction', {
			"qpId" : qpId,
		});
	}
	
	countryList(){
		return axios.get("https://restcountries.com/v2/all");
	}

}

export default new ApiService();

// 