import React, { useState, useEffect } from 'react';

import Chart from "react-apexcharts";
import DoughnutChart from './DoughnutChart';
import BarChart from './BarChart';

import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
} from "recharts";

// styles
//import useStyles from "./styles";
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
//import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import { Avatar, Grid, Button, Typography, CardHeader, Card, CardContent, CardActions,CardActionArea, CardMedia, Box, Paper, CircularProgress,Backdrop } from '@material-ui/core/';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaymentIcon from '@material-ui/icons/Payment';
import RefundIcon from '@material-ui/icons/RemoveShoppingCart';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import ApiService from '../ApiService/ApiService';
//import { Doughnut } from "react-chartjs-2";

import {  Bar, XAxis, YAxis, 
	CartesianGrid } from 'recharts';  


const useStyles = makeStyles(theme => ({

	avatarCheck: {
		backgroundColor: 'rgb(99, 117, 98)' ,

	},
	avataSett: {
		backgroundColor: 'rgb(173, 206, 171)',

	},
	avataChbk: {
		backgroundColor: 'rgb(153, 65, 65)',

	},
	avataRefund: {
		backgroundColor: 'rgba(239, 192, 4, 0.91)',

	},
	avataDecline: {
		backgroundColor: ' rgb(35, 75, 119)',

	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		},
}));

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();

  const data = [
    { name: 'Store 1', x: 12, y: 23, z: 122 },
    { name: 'Store 2', x: 22, y: 3, z: 73 },
    { name: 'Store 3', x: 13, y: 15, z: 32 },
    { name: 'Store 4', x: 44, y: 35, z: 23 },
    { name: 'Store 5', x: 35, y: 45, z: 20 },
    { name: 'Store 6', x: 62, y: 25, z: 29 },
    { name: 'Store 7', x: 37, y: 17, z: 61 },
    { name: 'Store 8', x: 28, y: 32, z: 45 },
    { name: 'Store 9', x: 19, y: 43, z: 93 },
];

const txn1=[
	{
		"currency": "USD",
		"acquirerMid": null,
		"successfulTxnCount": 61,
		"successfulTxnAmount": 1742653.14,
		"declinedTxnCount": 51,
		"declinedTxnAmount": 36204.89,
		"refundTxnCount": 19,
		"refundTxnAmount": 502607.3,
		"chbkTxnCount": 0,
		"chbkTxnAmount": 0,
		"successfulSettledCount": 0,
		"successfulSettledAmount": 0,
		"declinedSettledCount": 0,
		"declinedSettledAmount": 0,
		"chbkSettledCount": 0,
		"chbkSettledAmount": 0,
		"refundSettledCount": 0,
		"refundSettledAmount": 0,
		"month": null,
	},			
];
  
  const [txnSummary, setTxnSummary] = useState();
  const [monthSummary, setMonthSummary] = useState();
  const [open, setOpen] = React.useState(false);
  
  const handleClose = () => {
	  setOpen(false);
  };
  const handleToggle = () => {
	  setOpen(!open);
  };
  const handleData = () => {
	setTxnSummary(txn1);
};
  useEffect(() => { 
		  handleToggle();
		  const txnFn =  () =>  { 
			  	//const t3 =  ApiService.fetchMerchantTxnStatusSummary();				
			//   const t2 = await ApiService.fetchMonthsMerchantTxnSummary();
			const t1=[
				{
					"currency": "USD",
					"acquirerMid": null,
					"successfulTxnCount": 61,
					"successfulTxnAmount": 1742653.14,
					"declinedTxnCount": 51,
					"declinedTxnAmount": 36204.89,
					"refundTxnCount": 19,
					"refundTxnAmount": 502607.3,
					"chbkTxnCount": 0,
					"chbkTxnAmount": 0,
					"successfulSettledCount": 0,
					"successfulSettledAmount": 0,
					"declinedSettledCount": 0,
					"declinedSettledAmount": 0,
					"chbkSettledCount": 0,
					"chbkSettledAmount": 0,
					"refundSettledCount": 0,
					"refundSettledAmount": 0,
					"month": null,
				},			
			];
			
			const t2=[				
				{
					"currency": "USD",
					"acquirerMid": null,
					"successfulTxnCount": 0,
					"successfulTxnAmount": 9000.0,
					"declinedTxnCount": 0,
					"declinedTxnAmount": 0,
					"refundTxnCount": 0,
					"refundTxnAmount": 0,
					"chbkTxnCount": 0,
					"chbkTxnAmount": 0,
					"successfulSettledCount": 0,
					"successfulSettledAmount": 0,
					"declinedSettledCount": 0,
					"declinedSettledAmount": 0,
					"chbkSettledCount": 0,
					"chbkSettledAmount": 0,
					"refundSettledCount": 0,
					"refundSettledAmount": 0,
					"month": "June"
				},
				{
					"currency": "USD",
					"acquirerMid": null,
					"successfulTxnCount": 0,
					"successfulTxnAmount": 1210205.79,
					"declinedTxnCount": 0,
					"declinedTxnAmount": 0,
					"refundTxnCount": 0,
					"refundTxnAmount": 0,
					"chbkTxnCount": 0,
					"chbkTxnAmount": 0,
					"successfulSettledCount": 0,
					"successfulSettledAmount": 0,
					"declinedSettledCount": 0,
					"declinedSettledAmount": 0,
					"chbkSettledCount": 0,
					"chbkSettledAmount": 0,
					"refundSettledCount": 0,
					"refundSettledAmount": 0,
					"month": "July"
				},	
				{
					"currency": "INR",
					"acquirerMid": null,
					"successfulTxnCount": 0,
					"successfulTxnAmount": 1167683.54,
					"declinedTxnCount": 0,
					"declinedTxnAmount": 0,
					"refundTxnCount": 0,
					"refundTxnAmount": 0,
					"chbkTxnCount": 0,
					"chbkTxnAmount": 0,
					"successfulSettledCount": 0,
					"successfulSettledAmount": 0,
					"declinedSettledCount": 0,
					"declinedSettledAmount": 0,
					"chbkSettledCount": 0,
					"chbkSettledAmount": 0,
					"refundSettledCount": 0,
					"refundSettledAmount": 0,
					"month": "August"
				}
			];
			  
			//setTxnSummary(t1);		 
			setMonthSummary(t2);
			//console.log(t1.successfulTxnAmount);
			handleClose();
		  };
		  txnFn(); 
		}, []);
	

  // local
  var [mainChartState, setMainChartState] = useState("monthly");

  return (
    <>
      <PageTitle title=" Merchant Dashboard" />

	  <Grid container direction="row"  spacing={2} justify="flex-start">

	

	  {txn1 && txn1.map((postData) => {
		console.log(postData);
		return(
			<>
				<Grid item >
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Successful" className={classes.avatarCheck}>
									<CheckCircleIcon />
								</Avatar>
							}
							title="Successful"
							subheader={
								<><Typography variant="caption"> ${ postData.successfulTxnAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: { postData.successfulTxnCount}</Typography></>
							} 
						/>
					</Card >
				</Grid>
				
				<Grid item>
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Settled" className={classes.avataSett}>
									<ReceiptIcon />
								</Avatar>
							}
							title="Settled"
							subheader={
								<><Typography variant="caption"> ${ postData.successfulSettledAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: {postData.successfulSettledCount}</Typography></>
							} 
						/>
					</Card >

				</Grid>
				<Grid item> 
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Decline" className={classes.avataDecline}>
									<PaymentIcon />
								</Avatar>
							}
							title="Decline"
							subheader={
								<><Typography variant="caption"> ${postData.declinedTxnAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: { postData.declinedTxnCount}</Typography></>
							} 
						/>
					</Card > 
				</Grid>
				<Grid item>
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Chargeback" className={classes.avataChbk}>
									<RefundIcon />
								</Avatar>
							}
							title="Chargeback"
							subheader={
								<><Typography variant="caption"> ${postData.chbkTxnAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: { postData.chbkTxnCount}</Typography></>
							} 
						/>
					</Card >
				</Grid>
				<Grid item > 
					<Card>
						<CardHeader
							avatar={
								<Avatar aria-label="Refund" className={classes.avataRefund}>
									<RestoreIcon />
								</Avatar>
							}
							title="Refund"
							subheader={
								<><Typography variant="caption"> ${postData.refundTxnAmount.toFixed(2)}</Typography><br/>
								<Typography  variant="caption"> Count: {postData.refundTxnCount}</Typography></>
							} 
						/>
					</Card > 
				</Grid>
				</>
				);
	 })}
	</Grid>
						
			<Grid container direction="row"  
					spacing={2} xs={12} style={{ paddingTop: '1em' }}>
				<Grid item >
					 <BarChart monthSummary={monthSummary} /> 
				</Grid>
				<Grid item >
				{txn1 && txn1.map((postData) => {
					return(
						<>						
							<DoughnutChart txnSummary={postData}/> 
						</>
					);
				})}			
				</Grid>
			</Grid>


		

		 <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
		  <CircularProgress color="inherit" />
		</Backdrop>


		<br/><br/><br/>
		<Grid item xs={12}>
          <Widget
            title="Latest Transactions"
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <Table data={mock.table} />
          </Widget>
        </Grid>

		<br/><br/><br/>

		<Grid item lg={6} mt={5} md={4} sm={6} xs={14}>
          <Widget
            title="Payment Methods"
            upperTitle
			disableWidgetMenu={true}
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
				<Typography>
				<a ><img src="https://i.ibb.co/bFqJjFc/Social-Icon.png" alt="Social-Icon" border="0" /></a>
				</Typography><br/><br/>

				<Typography>
				<a><img src="https://i.ibb.co/hc4mW80/Social-Icon-1.png" alt="Social-Icon-1" border="0" /></a>
				</Typography><br/>


                </Grid>
                
              </Grid>
            </div>
           
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
				<Button variant="contained" color="primary">View More</Button>
                </Typography><br/>

				
            </Grid>
          </Widget>

		  <br/>
        </Grid>
		

	

	
		
    </>
  );
}

// #######################################################################
// function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
//   var array = new Array(length).fill();
//   let lastValue;

//   return array.map((item, index) => {
//     let randomValue = Math.floor(Math.random() * multiplier + 1);

//     while (
//       randomValue <= min ||
//       randomValue >= max ||
//       (lastValue && randomValue - lastValue > maxDiff)
//     ) {
//       randomValue = Math.floor(Math.random() * multiplier + 1);
//     }

//     lastValue = randomValue;

//     return { value: randomValue };
//   });
// }

// function getMainChartData() {
//   var resultArray = [];
//   var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
//   var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
//   var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

//   for (let i = 0; i < tablet.length; i++) {
//     resultArray.push({
//       tablet: tablet[i].value,
//       desktop: desktop[i].value,
//       mobile: mobile[i].value,
//     });
//   }

//   return resultArray;
// }


