const mock = {
  tasks: [
    {
      id: 0,
      type: "Meeting",
      title: "Meeting with Andrew Piker",
      time: "9:00"
    },
    {
      id: 1,
      type: "Call",
      title: "Call with HT Company",
      time: "12:00"
    },
    {
      id: 2,
      type: "Meeting",
      title: "Meeting with Zoe Alison",
      time: "14:00"
    },
    {
      id: 3,
      type: "Interview",
      title: "Interview with HR",
      time: "15:00"
    }
  ],
  bigStat: [
    {
      product: "Light Blue",
      total: {
        monthly: 4232,
        weekly: 1465,
        daily: 199,
        percent: { value: 3.7, profit: false }
      },
      color: "primary",
      registrations: {
        monthly: { value: 830, profit: false },
        weekly: { value: 215, profit: true },
        daily: { value: 33, profit: true }
      },
      bounce: {
        monthly: { value: 4.5, profit: false },
        weekly: { value: 3, profit: true },
        daily: { value: 3.25, profit: true }
      }
    },
    {
      product: "Sing App",
      total: {
        monthly: 754,
        weekly: 180,
        daily: 27,
        percent: { value: 2.5, profit: true }
      },
      color: "warning",
      registrations: {
        monthly: { value: 32, profit: true },
        weekly: { value: 8, profit: true },
        daily: { value: 2, profit: false }
      },
      bounce: {
        monthly: { value: 2.5, profit: true },
        weekly: { value: 4, profit: false },
        daily: { value: 4.5, profit: false }
      }
    },
    {
      product: "RNS",
      total: {
        monthly: 1025,
        weekly: 301,
        daily: 44,
        percent: { value: 3.1, profit: true }
      },
      color: "secondary",
      registrations: {
        monthly: { value: 230, profit: true },
        weekly: { value: 58, profit: false },
        daily: { value: 15, profit: false }
      },
      bounce: {
        monthly: { value: 21.5, profit: false },
        weekly: { value: 19.35, profit: false },
        daily: { value: 10.1, profit: true }
      }
    }
  ],
  notifications: [
    {
      id: 0,
      icon: "thumbs-up",
      color: "primary",
      content:
        'Ken <span className="fw-semi-bold">accepts</span> your invitation'
    },
    {
      id: 1,
      icon: "file",
      color: "success",
      content: "Report from LT Company"
    },
    {
      id: 2,
      icon: "envelope",
      color: "danger",
      content: '4 <span className="fw-semi-bold">Private</span> Mails'
    },
    {
      id: 3,
      icon: "comment",
      color: "success",
      content: '3 <span className="fw-semi-bold">Comments</span> to your Post'
    },
    {
      id: 4,
      icon: "cog",
      color: "light",
      content: 'New <span className="fw-semi-bold">Version</span> of RNS app'
    },
    {
      id: 5,
      icon: "bell",
      color: "info",
      content:
        '15 <span className="fw-semi-bold">Notifications</span> from Social Apps'
    }
  ],
  table: [
    {
      id: 0,
      date: "19:06 - 25.08.22",
      name: "Store 1",
      terminal: "GUS272HDB8",
      total: "$400",
      payment_status: "Completed",
      payment_method:"Mastercard"
    },
    {
      id: 1,
      date: "19:02 - 25.08.22",
      name: "Store 2",
      terminal: "A2S272ZXB92",
      total: "$600",
      payment_status: "Chargeback",
      payment_method:"Visa"
    },
    {
      id: 2,
      date: "19:00 - 25.08.22",
      name: "Store 3",
      terminal: "GUS272HDB8",
      total: "$300",
      payment_status: "Refund",
      payment_method:"Mastercard",
    
    },
    {
      id: 3,
      date: "18:52 - 25.08.22",
      name: "Store 4",
      terminal: "A2S272ZXB92",
      total: "$500",
      payment_status: "Completed",
      payment_method:"Visa",
     
    },
    {
      id: 4,
      date: "18:48 - 25.08.22",
      name: "Store 5",
      terminal: "Z9A272Z32VA",
      total: "$200",
      payment_status: "Completed",
      payment_method:"Paypal",

    }
  ],

  onHoldTable: [
    {
      id: 0,
      date: "19:06 - 25.08.22",
      name: "Store 1",
      terminal: "GUS272HDB8",
      total: "$400",
      payment_status: "On Hold",
      payment_method:"Mastercard"
    },
    {
      id: 1,
      date: "19:02 - 25.08.22",
      name: "Store 3",
      terminal: "A2S272ZXB92",
      total: "$600",
      payment_status: "On Hold",
      payment_method:"Visa"
    },
    {
      id: 2,
      date: "19:00 - 25.08.22",
      name: "Store 3",
      terminal: "GUS272HDB8",
      total: "$300",
      payment_status: "On Hold",
      payment_method:"Mastercard",
    
    },   
  ],

  users:[
    {
      id:0,
      name:"David McHenry",
      email:"david@papel.com",
      roles:"Owner"

    },

    {
      id:1,
      name:"Frank Kirk",
      email:"frank@papel.com",
      roles:"Accounting Support"

    },

    {
      id:2,
      name:"Rafael Morales",
      email:"Rafael@papel.com",
      roles:"Accounting"

    },

  ],

  terminals:[
    {
      id:0,
      terminal:"GUS272HDB8"
    },
    {
      id:1,
      terminal:"A2S272ZXB92"
    },

    {
      id:2,
      terminal:"GUS272HDB8"
    },

    {
      id:3,
      terminal:"A2S272ZXB92"
    },

    {
      id:4,
      terminal:"GUS272HDB8"
    }
  ],

  stores:[
    {
      id:0,
      store:"store 1",
      name:" Domino's ",
      address:"Mumbai",
    },
    {
      id:0,
      store:"store 2",
      name:" Flipkart ",
      address:"Pune",
    },

    {
      id:0,
      store:"store 3",
      name:" Amazon ",
      address:"London",
    },

    {
      id:0,
      store:"store 4",
      name:" Myntra",
      address:"Noida",
    },

    {
      id:0,
      store:"store 1",
      name:" wellness forever",
      address:"Mumbai",
    }
  ]
};

export default mock;