import React from "react";
import ApiService from "../pages/ApiService/ApiService";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, registerUser , signOut };

// ###########################################################

function registerUser(dispatch, login, password, history, setIsLoading, setError) {

  const formData = {
    'userName': login,
    'password': password,				
    }

  ApiService.createUser(formData)
    .then((res) => {
        if(res.data.status == 'OK') {

          setTimeout(() => {
            localStorage.setItem('id_token', 1)
            setError(null)
            setIsLoading(false)           
      
            history.push('/login')
          }, 2000);
            
        } else {
        
        }
            
    }).catch((e) => {      
      dispatch({ type: "LOGIN_FAILURE" });
      setError(true);
      setIsLoading(false);
  })

}

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);
  

  ApiService
    .executeJwtAuthenticationService(login,password)
    .then(res => {
        ApiService.registerSuccessfulLoginForJwt(login, res.data.auth_token)
    ApiService.getCurrentUser().then((user) => {
    if(user.data) {

      //this.props.history.push(`/home`)
     
      //handler(user.data)
      setTimeout(() => {
        localStorage.setItem('id_token', 1)
        setError(null)
        setIsLoading(false)
        dispatch({ type: 'LOGIN_SUCCESS' })
  
        history.push('/app/dashboard')
      }, 2000);
    }else{
      dispatch({ type: "LOGIN_FAILURE" });
      setError(true);
      setIsLoading(false); 
    }


    });
      
  }).catch(() => {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  })

  

  
  // if (!!login && !!password) {    

  //   setTimeout(() => {
  //     localStorage.setItem('id_token', 1)
  //     setError(null)
  //     setIsLoading(false)
  //     dispatch({ type: 'LOGIN_SUCCESS' })

  //     history.push('/app/dashboard')
  //   }, 2000);
  // } else {
  //   dispatch({ type: "LOGIN_FAILURE" });
  //   setError(true);
  //   setIsLoading(false);
  // }
}

function signOut(dispatch, history) {
  ApiService.logout();
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  //history.push("/");
  window.location.href = "/";
}
