import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ApiService from '../ApiService/ApiService';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddBox from '@material-ui/icons/AddBox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import EditFraudRulesForm from './EditFraudRulesForm';
import MUIDataTable from "mui-datatables";
import PageTitle from "../../components/PageTitle/PageTitle";

const useStyles = theme => ({	

  detailhead: {
	 fontSize: 12,
  },
  formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  buttonProgress: {
    position: 'absolute',
	marginTop: 8,
    marginLeft: -65,
  },
  button: {
    margin: theme.spacing(1),
  },    
  Dialog:{
	
  }
  
});

class fraudRulesManagementComponent extends Component {
	
	constructor(props) {
        super()
        this.state = {
            merchantId: null,
			selectedMerchant: null,
			selectedTerminal: null,
			failed: false,
			success: false,
			responsemessage: null,
			merchants: [],
			rules: [],		
			fraudrulesmer:[],
			loading: false,		
			fraudName:'',			
			previous:[],	
			addRulesVisible:false,			
		}
		this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);			
		//this.fetchAllMerchants = this.fetchAllMerchants.bind(this);			
		this.fetchBusinessDetails = this.fetchBusinessDetails.bind(this);			
		this.showRulesOfSelectedMerchant = this.showRulesOfSelectedMerchant.bind(this);			
		this.showAddRulesDialogue = this.showAddRulesDialogue.bind(this);
		this.hideAddRules = this.hideAddRules.bind(this);
		this.reloadData();
				
	}	

	showAddRulesDialogue() {
		this.setState({addRulesVisible: true});
	}

	hideAddRules() {
		this.setState({addRulesVisible: false});
	}
			
	componentDidMount(){
		this.fetchBusinessDetails();	
	}
		
	handleChange(event) {		
		const { name, value } = event.target;		
		this.setState({[name]: value,success:false});
    }

	reloadData() {
		//this.fetchAllMerchants();
		this.setState({errors: ''});									
	} 	

	showRulesOfSelectedMerchant(){		

		ApiService.fetchFraudRulesByMerchantId(this.state.merchants.id)
			.then((res)=> {
				this.setState({fraudrulesmer: res.data});									
			});	
		
	}

	fetchBusinessDetails(){			

			ApiService.getUserMerchant().then((res) => {
				//setMerchant(res.data);               
				this.setState({merchants: res.data});
			}); 
	}
				
	tableIcons = {
	    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
	};
	
	render(){
		const { classes } = this.props; 
		const {errors} = this.state;
		return (
			<>	
			
			<React.Fragment>
				<Paper style={{margin: '1em', padding: '1em'}}>
					   
					   <PageTitle title="Merchant Fraud Rules management" />
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responseMessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0">{this.state.responseMessage}</Alert>}
					&nbsp;
					
					<Grid justify="center" alignItems="center" style={{marginTop: '2em'}} container xs={12}>					
						
							<Grid item xs={4}>
								<Button
									variant="contained"
									color="primary"
									size="small"
									className={classes.button}
									onClick= {this.showRulesOfSelectedMerchant}
								>			
									Show Rules
								</Button>
								{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}

								{/* <Button
									variant="contained"
									color="primary"
									size="small"
									className={classes.button}
									onClick= {this.showAddRulesDialogue}
								>			
									Add rules
								</Button>
								{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />} */}
							</Grid>
						
							<></>
						
					</Grid>

				
					<MUIDataTable
					  
				      title="Configuerd Rules" 
					 
				      columns={[ 				        
				        { name: 'fraudName' , label: 'Rule'},
						{ name: 'fraudValue',label: 'Rule Value'},										
				      ]}
				      data={this.state.fraudrulesmer}
					  actions={[
						
						
					  ]}
					  options={{
						search:true,
				        exportButton: false,																	
						selectableRowsHideCheckboxes: true,							
				      }}				     
				    />	
				

					<Dialog fullWidth maxWidth="sm"
					open={this.state.addRulesVisible} onClose={this.hideAddRules}>
						<DialogTitle id="customized-dialog-mapterminal" onClose={this.hideAddRules}>
							Rules to Apply :
				        </DialogTitle>
						<DialogContent>
							 <EditFraudRulesForm merchantId={this.state.selectedMerchant && this.state.selectedMerchant.id} reloadTerminals={this.showFraudRules}/>
						</DialogContent>
					</Dialog>
																											
																												
				</Paper>
			</React.Fragment>
			</>
		);
		
	}
}
export default withStyles(useStyles)(fraudRulesManagementComponent);